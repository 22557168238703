import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { ContentPlusDecor } from "../../../components/widgets/content-plus-decor";
import { FaBalanceScale } from "react-icons/fa";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Disclaimer`}</h1>
    <Breadcrumbs to="/company/" text="Company" mdxType="Breadcrumbs" />
    <ContentPlusDecor decor={<FaBalanceScale mdxType="FaBalanceScale" />} mdxType="ContentPlusDecor">
      <p>{`This website represents an assessment of the market and economic environment at a specific point in time, is not intended to be a forecast of future events or a guarantee of future results, and is meant to present ideas for further research and analysis and should not be taken as a recommendation to invest. It does not provide individualized advice or recommendations for any specific reader. Forward-looking statements are subject to certain risks and uncertainties, and we may not cover all relevant risks related to the ideas presented on this site. Actual results, performance, or achievements may differ materially from those expressed or implied. Information is based on data gathered from what we believe are reliable sources. It is not guaranteed to be accurate, does not purport to be complete, and is not intended to be used as a primary basis for investment decisions. It should not be construed as advice meeting the particular investment needs of any investor. Readers should conduct their own due diligence and carefully consider their own investment objectives, risk tolerance, time horizon, tax situation, liquidity needs, and concentration levels, or contact a professional adviser to determine if any ideas presented here are appropriate for their unique circumstances.`}</p>
    </ContentPlusDecor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      